.personalForm h3,
.employeForm h3 {
  margin-bottom: 1rem;
}

.staffForm_container {
  background-color: #f6f5f5;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  margin-top: 1rem;
  box-shadow: 0px 0px 7px 0px #00000040;
}

.staffForm_containerr {
  height: 150px;
}

.inputDiv1 {
  display: flex;
  align-items: center;
  border: 1px solid var(--light-text);
  border-radius: 10px;
  padding: 0.5rem;
  background-color: var(--primary-bg);
  height: 45px;
}

.curser_Pointer {
  cursor: pointer;
}

.curser_Pointer input {
  cursor: pointer;
}

.addresMatching input {
  margin-left: 0.6rem;
  height: 1.3rem;
  width: 1.6rem;
}

.inputDiv1 img {
  width: 1.7rem;
}

.inputDiv1 input {
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
}

.input_field input {
  padding: 0.7rem;
  outline: 1px solid #666666;
}

.no_prsnl_id {
  font-size: 15px;
  padding-left: 1rem;
  margin-bottom: 0.3rem;
  color: var(--light-text);
}

.no_prsnl_id label {
  font-size: 10px;
  font-weight: 400;
}

.imgDiv {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.imgDiv img {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.inputDiv1 select {
  width: 100%;
  border: none;
  height: 35px;
  background-color: var(--primary-bg);
  outline: none;
  cursor: pointer;
}

.input_field_custom2 {
  font-size: 14px;
  border: 1px solid var(--light-text);
  padding: 0.5rem;
  border-radius: 6px;
  width: 100%;
  padding-left: 45px;
  height: 40px;
  background-color: var(--primary-bg);
}

.input_field_custom3 {
  font-size: 14px;
  border: 1px solid var(--light-text);
  padding: 0.5rem;
  border-radius: 6px;
  width: 100%;
  height: 50px;
  background-color: var(--primary-bg);
}

.input_field_custom4 {
  display: flex;
  font-size: 14px;
  background-color: var(--secondary-color) !important;
  box-shadow: 0px 1px 3px 0px #00000033;
  align-items: center;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  position: relative;
  padding-left: 45px;
  height: 40px;
}

.noteMsg {
  font-size: 11px;
  padding-left: 1rem;
}

.inpContainer {
  margin-bottom: 1rem;
}

.inputDiv1 textarea {
  width: 100%;
  border: none;
  outline: none;
}

textarea {
  resize: none;
  height: 100px !important;
}

.saveFormBtns {
  margin: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.saveFormBtns button {
  border: none;
  padding: 0.6rem 1.5rem;
  width: 130px;
  border-radius: 6px;
}

.saveFormBtns .btnCancel {
  color: var(--light-text);
  background-color: var(--light-bg);
}

.saveFormBtns .btnSave {
  color: var(--white-text);
  background-color: var(--blue-bg);
}

.labelCheckbox {
  font-size: 12px;
}

.imginputContainer {
  height: 90%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgInput {
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 50%;
}

.imgInput p {
  font-size: 14px;
}

.imgValidationalert p {
  font-size: 10px;
  margin-bottom: 2px;
  align-items: center;
  color: var(--error-text);
}

.imgInptImg {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

/* -------------------------------------------View Staff Styles------------------------------------------- */

.labelView {
  color: var(--light-text);
  font-size: 14px;
  padding-left: 0.5rem;
}

.inputDiv2 {
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  position: relative;
  box-shadow: 0px 1px 3px 0px #00000033;
  height: 40px;
}

.empoyeeButtn {
  margin-top: 2rem;
}

.bottomAlgin {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.centerAlgin {
  display: flex;
  align-items: center;
}

.centerAlginHorizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerAlgin .inputDiv2 {
  width: 100%;
}

.inputDiv2 img {
  width: 1rem;
}

.inputDiv2 input {
  border: none;
  background-color: transparent;
  width: 100%;
  outline: none;
}

.labelPointer {
  cursor: pointer;
}

.inputDiv2 select {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.inputDiv2 .btnSearch {
  background-color: var(--grey-bg);
  color: var(--white-text);
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 6px;
  position: absolute;
  right: 6px;
  top: 3px;
}

.btnAddStaff {
  background-color: var(--primary-color);
  color: var(--white-text);
  border: none;
  border-radius: 10px;
  padding: 0.5rem;
  width: 100%;
}

.viewStaff_table_head {
  margin-bottom: 1rem;
  color: var(--light-black);
}

.prsnlDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-right: 1px solid var(--blue-grey);
  padding: 1.5rem 0.5rem;
}

.prsnlDetails img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.prsnlDetailsText .workingEmp {
  background-color: var(--lightGreen-bg);
  width: fit-content;
  padding: 0.2rem 0.5rem;
  border-radius: 6px;
  margin-bottom: 5px;
  color: var(--secondary-text);
}

.prsnlDetailsText .non_workingEmp {
  background-color: var(--lightRed-bg);
  width: fit-content;
  padding: 0.2rem 0.5rem;
  border-radius: 6px;
  margin-bottom: 5px;
  color: var(--white-text);
}

.prsnlDetailsText p {
  margin: 0;
  color: var(--secondary-text);
  font-size: 15px;
}

.empDetailsText p {
  font-size: 16px !important;
  margin-bottom: 0.5rem;
}

.viewStaff_table_item {
  margin-top: 0.7rem;
  background-color: var(--primary-bg);
  box-shadow: 0px 0.9953445196151733px 6.967411518096924px 0px #00000040;
  border-radius: 10px;
}

.prsnlDetailsSocial ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}

.prsnlDetailsSocial ul li {
  cursor: pointer;
}

.prsnlDetailsSocial ul img {
  width: 1.5rem;
}

.on-off-toggle {
  width: 58px;
  height: 25px;
  position: relative;
  display: inline-block;
}

.on-off-toggle__slider {
  width: 58px;
  height: 25px;
  display: block;
  border-radius: 34px;
  background-color: #d8d8d8;
  transition: background-color 0.4s;
  cursor: pointer;
}

.on-off-toggle__slider:before {
  content: "";
  display: block;
  background-color: #fff;
  box-shadow: 0 0 0 1px #949494;
  bottom: 2px;
  height: 21px;
  left: 3px;
  position: absolute;
  transition: 0.4s;
  width: 21px;
  z-index: 5;
  border-radius: 100%;
}

.on-off-toggle__slider:after {
  display: block;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 5px;
  font-weight: bold;
  content: "Deactive";
  color: var(--light-text);
  padding-left: 26px;
  transition: all 0.4s;
  bottom: 2px;
  position: absolute;
}

.on-off-toggle__input {
  position: absolute;
  opacity: 0;
}

.on-off-toggle__input:checked + .on-off-toggle__slider {
  background-color: var(--lightBlue-bg);
}

.on-off-toggle__input:checked + .on-off-toggle__slider:before {
  transform: translateX(31px);
}

.on-off-toggle__input:checked + .on-off-toggle__slider:after {
  content: "Active";
  color: #ffffff;
  padding-left: 8px;
}

.actionsSections {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.actionsSections img {
  width: 1.5rem;
}

.sendEmailBtn {
  display: flex;
  align-items: center;
  border: none;
  gap: 0.3rem;
  padding: 0.3rem 1rem;
  border-radius: 6px;
  box-shadow: 0px 0.9953445196151733px 6.967411518096924px 0px #00000040;
  background-color: var(--primary-bg);
  margin-top: 1rem;
}

.sendEmailBtn p {
  margin: 0;
  color: var(--secondary-text);
  font-size: 15px;
}

.sendEmailBtn img {
  width: 1.5rem;
}

.editStaffBtn {
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
}

.confirmModal .modal-content {
  border-radius: 20px;
}

.confirmModal .confirmModalHeader {
  border: none;
}

.confirmModal .confirmModalFooter {
  border: none;
  justify-content: center;
}

.confirmModalFooter {
  display: flex;
  gap: 10px;
}

.confirmAeBtn,
.confirmDeBtn,
.Confirm_Deactive,
.closeConfirmBtn {
  width: 120px;
  border: none;
}

.confirmDeBtn {
  background-color: var(--lightBlack-bg);
  border: 1px solid transparent;
}

.confirmAeBtn {
  background-color: var(--lightBlack-bg);
  border-color: var(--lightBlack-bg);
  border: 1px solid transparent;
}

.confirmDeBtn:hover {
  background-color: var(--lightRed-bg);
  border: 1px solid var(--secondary-text);
  color: var(--secondary-color);
}

.confirmAeBtn:hover {
  background-color: var(--lightGreen-bg);
  border: 1px solid var(--secondary-text);
  color: var(--secondary-text);
}

.closeConfirmBtn {
  color: var(--secondary-color);
  border-color: var(--secondary-text);
  background-color: var(--light-bg) !important;
  color: var(--light-black);
}

.closeConfirmBtn:hover {
  background-color: var(--lightBlack-bg) !important;
  border: 1px solid var(--secondary-text);
  color: var(--secondary-color);
  border: none;
}

.Confirm_Deactive:hover {
  background-color: var(--primary-color);
  color: var(--white-text) !important;
}

.closeConfirmAeBtn:hover {
  background-color: var(--lightRed-bg);
  border: 1px solid var(--secondary-text) !important;
  color: var(--white-text) !important;
}

.confirmBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirmBody img {
  width: 4rem;
}

.confirmBody .modalText {
  color: var(--secondary-text);
  margin: 0;
  text-align: center;
}

.modalTextRed {
  color: var(--red-text);
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .prsnlDetails {
    gap: 0.5rem;
  }

  .editstaff_container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    border-top: 1px solid var(--blue-grey);
    width: 100%;
    padding: 1rem 0;
  }

  .paddingZero {
    padding: 0;
  }

  .sendEmailBtn {
    margin-top: 0;
  }
}

.disabledIcon {
  cursor: not-allowed;
}

/* -------------------------------------------------Guest View Css---------------------------------------------- */

.sendEmailBtnGuest img {
  width: 1.5rem;
}

@media screen and (max-width: 991px) {
  .sendEmailBtnGuest img {
    width: 1rem;
  }

  .sendEmailBtn p {
    font-size: 12px;
  }

  .guest_incenter {
    font-size: 13px;
  }
}

.sendEmailBtnGuest {
  margin: auto !important;
}

.theadStyle {
  padding: 0.5rem;
}

.tbodyStyle1 {
  padding: 0 0.5rem;
  color: var(--light-text);
}

.tbodyStyle {
  color: var(--light-text);
}

.viewGuest_table_container .table > :not(caption) > * > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: none !important;
  /* text-align: center; */
}

.viewGuest_table_container tbody tr {
  border-radius: 10px;
}

.borderLeftRadius {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.borderRightRadius {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.viewGuest_table_container .table {
  width: 100%;
  border-collapse: collapse;
}

.theadStyle {
  color: var(--light-black);
  font-weight: 500;
}

.tboday .th1,
.theadStyle .th1 {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.tboday .th2,
.theadStyle .th2 {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.theadStyle {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tboday {
  overflow-y: auto;
  max-height: 400px;
}

.tableRow {
  box-shadow: 0px 0.9953445196151733px 6.967411518096924px 0px #00000040;
}

.imgThead {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 0.2rem;
  padding-left: 0.5rem;
}

.imgThead img {
  width: 1rem;
}

.hidden {
  display: none !important;
}

.guest_incenter {
  text-align: center;
  height: 42px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-left: 0.5rem;
}

.dropDownn {
  position: relative;
  padding-left: 2rem;
}

.dropDownn img {
  height: 13px;
  width: 13px;
  position: absolute;
  top: 14px;

  right: 10px;
}

@media screen and (max-width: 991px) {
  .personalForm h3,
  .employeForm h3 {
    margin-top: 10px;
  }
}

.markVip {
  background-color: var(--light-bg);
  border: none;
  border-radius: 20px;
  padding: 0.2rem 1rem;
  margin: auto;
}

.markVip p {
  margin: 0;
  font-size: 13px;
  color: var(--light-text);
  font-weight: 500;
  padding-top: 0.1rem;
}

.vipMarked {
  background-color: var(--green-bg) !important;
}

.vipMarkedred {
  background-color: var(--lightRed-bg) !important;
}

.vipMarked p {
  color: var(--white-text);
}
.vipMarkedred p {
  color: var(--white-text);
}
.colorred {
  color: var(--lightRed-bg) !important;
}

.tabledata {
  align-content: center;
}

/* ----------------------------------Profile settings Styles------------------------------------- */

@media screen and (min-width: 991px) {
  .heightBefore991 {
    height: 88%;
  }
}

.max_height_500px {
  min-height: 581px;
}

.float_right_table {
  float: right;
  color: var(--primary-color);
  cursor: pointer;
}

.justify_center {
  justify-content: center;
}

.pageNameDiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}

.pageNameDiv p {
  margin: 0;
  color: var(--secondary-text);
  font-weight: 500;
}

.pageNameDiv img {
  width: 0.6rem;
}

.pageCntn_head {
  padding: 0.8rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageCntn_head_left {
  display: flex;
  align-items: center;
}

.redtext {
  color: var(--red-text) !important;
}

.profileImgEdit {
  width: 1rem !important;
  height: 1rem !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: var(--primary-bg);
}

.text_align_center {
  text-align: center;
  justify-content: center;
}

/* -------------------------------------------Website Management Pages Styles--------------------------------------------- */

.newImgInp {
  border: none !important;
  box-shadow: 0px 3.6369197368621826px 3.6369197368621826px 0px #00000040;
}

.newImgInp p {
  color: var(--light-text);
  font-size: 14px;
}

.stallLogo {
  width: 2.5rem !important;
}

.Stall_Img::before {
  background-image: url("../../assets/storefront.png");
}

.tag_line::before {
  background-image: url("../../assets/shoppingmode.svg");
}

.orange_phone::before {
  background-image: url("../../assets/orangePhone.svg");
}

.ornage_email_image::before {
  background-image: url("../../assets/orangeEmail.svg") !important;
}

.width_20px {
  width: 20px;
}

.pagination {
  --bs-pagination-border-width: 0px !important;
  --bs-pagination-color: var(--bs-secondary-color);
}

.tboday {
  user-select: text !important;
}

.guesticon {
  border: none;
  background: transparent;
  margin: auto;
}

.guest_incenterActions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.h-90 {
  height: 90% !important;
}

.h-26px {
  height: 26px;
}

.imgCopyIcon {
  border: transparent;
  background-color: transparent;

  img {
    height: 20px;
    width: 20px;
  }
}

.uploadImgs {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  button {
    border: none;
    color: white;
    padding: 0.6rem 1rem 0.6rem 1rem;
    border-radius: 30px;
    background-color: #0087f7;
  }
}

.card-header {
  box-shadow: 0px 0px 7px 0px #00000040;
  border-radius: 10px;
  padding: 1rem 1rem;
}

.dropzone {
  border: 2px dashed #0087f7;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  margin-top: 1rem;
}

.dropzone p {
  font-size: 14px;
  margin: 0;
  color: var(--fade-text);
  font-weight: 500;
}

.thumbs-container {
  position: relative;
  display: flex;
  /* width: 100%; */
  flex-direction: row;
  gap: 1rem;

  flex-wrap: wrap;

  margin-top: 16px;

  img {
    height: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.21);
  }
}

.upload {
  height: 50px;
  width: 50px;
  opacity: 0.2;
}

.upladImgLabel {
  font-size: 13px;
  color: rgba(128, 128, 128, 0.66);
}

.thumb {
  position: relative;
  margin: 8px;
  height: 100px;
  width: 100px;
  box-sizing: border-box;
}

.thumb-img {
  display: block;
  width: 100%;
  height: 100%;
}

.input-file {
  display: none;
}

.remove-icon {
  background-color: rgba(5, 3, 3, 0.8);
  position: absolute;
  z-index: 30;
  border-radius: 10px;
  padding: 4px;
  top: 5px;
  right: 5px;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
  border: none !important;
}

.input_field_custom1_add_restro {
  padding: 0;
  margin-bottom: 0.5rem;
}

.bodyText p {
  text-align: left !important;
  margin-bottom: 0.5rem !important;
}

.bodyText {
  width: 100%;
}

.modal-header .btn-close:focus,
.modal-header .btn-close:focus-visible {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.card_imagesUpload {
  position: relative;
}

.card_imagesUpload  img {
  border-radius: 3px;
}

.container_card {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0 !important;
  border-radius: 50%;
}

.container_card img {
  padding: 3px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
}